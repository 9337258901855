import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Icon from "components/web/icon";
import Theme from "theme";

import SlideArrow from "assets/images/menu/icon-slide-arrow.svg";

export default (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);

  let viewMode = props?.viewMode;

  // const itemsPerPage = viewMode === "mobile" ? 1 : 4;
  const itemsPerPage = props?.itemsPerPage;
  const { item } = props;

  useEffect(() => {
    if (item?.length) {
      setTitle(item[0]?.title || "");
      setDescription(item[0]?.description || "");
    }
  }, []);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleCardClick = (index) => {
    setSelectedIndex(index);
    setTitle(item[index]?.title);
    setDescription(item[index]?.description);
  };

  const handleNext = () => {
    const nextIndex = (selectedIndex + 1) % item.length;
    setSelectedIndex(nextIndex);
    setTitle(item[nextIndex]?.title || "");
    setDescription(item[nextIndex]?.description || "");

    if (nextIndex >= visibleStartIndex + itemsPerPage) {
      const newStartIndex = nextIndex - itemsPerPage + 1;
      setVisibleStartIndex(newStartIndex >= 0 ? newStartIndex : 0);
    }
    if (nextIndex === 0) {
      setVisibleStartIndex(0);
    }
  };

  const handlePrev = () => {
    const prevIndex = (selectedIndex - 1 + item.length) % item.length;
    setSelectedIndex(prevIndex);
    setTitle(item[prevIndex]?.title || "");
    setDescription(item[prevIndex]?.description || "");

    if (prevIndex < visibleStartIndex) {
      const newStartIndex = prevIndex;
      setVisibleStartIndex(newStartIndex >= 0 ? newStartIndex : 0);
    }

    if (prevIndex === item.length - 1) {
      setVisibleStartIndex(Math.max(0, item.length - itemsPerPage));
    }
  };

  const visibleItems = item.slice(
    visibleStartIndex,
    visibleStartIndex + itemsPerPage
  );

  return {
    render: (
      <Fade top>
        {Render({
          component: "container",
          style: {
            width: "auto",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            transition: "min-height 0.5s ease, transform 0.5s ease",
            paddingTop: Theme.sizes.size_18,
            gap: Theme.sizes.size_48,
            ...props?.style?.boxMain,
          },
          children: [
            // Text info
            {
              component: "container",
              style: {
                display: "flex",
                width: "auto",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: Theme.sizes.size_12,
                ...props?.style?.boxText,
              },
              children: [
                //o que fazemos
                {
                  component: "text",
                  text: "O QUE OFERECEMOS",
                  style: {
                    color: Theme.colors.primary.extra_ligth,
                    fontSize: Theme.sizes.size_20,
                    fontFamily: Theme.fontFamilies.primary,
                    ...props?.style?.textIntro,
                  },
                },
                //title
                {
                  render: (
                    <Fade top key={item?.title} duration={500}>
                      <div
                        style={{
                          color: Theme.colors.secondary.regular,
                          textAlign: "center",
                          fontSize:
                            viewMode === "desktop"
                              ? Theme.sizes.size_48
                              : Theme.sizes.size_28,
                          fontFamily: Theme.fontFamilies.primary,
                          transition: "color 0.3s ease, transform 0.3s ease",
                        }}
                      >
                        {title}
                      </div>
                    </Fade>
                  ),
                },
                //description
                {
                  render: (
                    <Fade top key={item?.description} duration={500}>
                      <div
                        style={{
                          width: viewMode === "desktop" ? 446 : "92%",
                          height: 226,
                          color: Theme.colors.primary.extra_ligth,
                          fontSize: Theme.sizes.size_18,
                          fontFamily: Theme.fontFamilies.regular,
                          fontWeight: "200",
                          textAlign: "justify",
                          padding:
                            viewMode === "desktop" ? 0 : Theme.sizes.size_4,
                          transition: "color 0.3s ease, transform 0.3s ease",
                          lineHeight: 1.2,
                          wordSpacing: "0.01em",
                          hyphens: "auto",
                        }}
                      >
                        {description}
                      </div>
                    </Fade>
                  ),
                },
              ],
            },
            // Card Main
            {
              component: "container",
              style: {
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                ...props?.style?.boxCard,
              },
              children: [
                Icon({
                  src: SlideArrow,
                  size: Theme.sizes.size_32,
                  style: {
                    image: {
                      transform: "rotate(-180deg)",
                    },
                  },
                  onPress: handlePrev,
                }),
                {
                  component: "container",
                  style: {
                    width: "auto",
                    height: "auto",
                    display: "flex",
                    flexDirection: "row",
                    padding: Theme.sizes.size_12,
                    gap: Theme.sizes.size_14,
                    transition: "min-height 0.5s ease, transform 0.5s ease",
                  },
                  children: visibleItems.map((menu, index) => {
                    const isSelected = selectedIndex === index;
                    const isHovered = hoveredIndex === index;
                    return {
                      render: (
                        <Fade top>
                          {Render({
                            component: "container",
                            style: {
                              width: Theme.sizes.size_256,
                              height: Theme.sizes.size_192,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              border: "4px solid",
                              borderColor:
                                isSelected && viewMode === "desktop"
                                  ? Theme.colors.secondary.regular
                                  : "#008174" && isHovered
                                  ? Theme.colors.secondary.extra_light
                                  : Theme.colors.secondary.regular,
                              transition:
                                "border-color 0.3s ease, transform 0.3s ease",
                              borderRadius: 12,
                              ...props?.style?.cardItem,
                            },
                            children: [
                              {
                                render: (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      height: "100%",
                                      alignItems: "center",
                                      textAlign: "center",
                                      justifyContent: "center",
                                      opacity:
                                        isSelected && viewMode === "desktop"
                                          ? 1
                                          : 0.6 && viewMode === "mobile"
                                          ? 1
                                          : 0.6,
                                      fontSize: Theme.sizes.size_24,
                                      fontFamily: Theme.fontFamilies.primary,
                                      transition: "transform 0.5s ease",
                                      transform:
                                        isSelected && viewMode === "desktop"
                                          ? "scale(1.1)"
                                          : "scale(1)",
                                      ...props?.style?.cardImage,
                                    }}
                                    onClick={() =>
                                      viewMode === "desktop"
                                        ? handleCardClick(index)
                                        : ""
                                    }
                                    onMouseEnter={() =>
                                      viewMode === "desktop"
                                        ? handleMouseEnter(index)
                                        : ""
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {Render({
                                      component: "image",
                                      src: menu?.image,
                                      style: {
                                        display: "flex",
                                        width: "100%",
                                        height: "100%",
                                        alignItems: "center",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        borderRadius: 10,
                                        color:
                                          isSelected && viewMode === "desktop"
                                            ? "#008174"
                                            : Theme.colors.secondary.regular &&
                                              isHovered
                                            ? Theme.colors.secondary.regular
                                            : Theme.colors.secondary
                                                .extra_light,
                                        fontSize: Theme.sizes.size_24,
                                        fontFamily: Theme.fontFamilies.primary,
                                        transition: "transform 0.5s ease",
                                      },
                                    })}
                                  </div>
                                ),
                              },
                            ],
                          })}
                        </Fade>
                      ),
                    };
                  }),
                },
                Icon({
                  src: SlideArrow,
                  size: Theme.sizes.size_32,
                  style: {
                    image: {
                      cursor: "pointer",
                    },
                  },
                  onPress: handleNext,
                }),
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
