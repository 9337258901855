// Default imports
import { Render } from "@8_dev/sjson-web";

import { useState, useEffect, useRef } from "react";
import { ShowPage } from "context/showPage";
import Fade from "react-reveal/Fade";
import { ShowPopup } from "context/popup";

// Theme
import Theme, { setTheme, getTheme } from "theme";

//components
import Button from "components/web/button";
import Icon from "components/web/icon";
import Input from "components/web/input";
import PopUp from "components/web/popup";

export default ({ viewMode, idxPageY, navigation, setNavigation }) => {
  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: 32,
      overflow: "hidden",
    },
    children: [
      //textIntro
      {
        component: "text",
        text: "Nossa história",
        style: {
          width: "100%",
          textAlign: "flex-start",
          fontSize:
            viewMode === "desktop" ? Theme.sizes.size_32 : Theme.sizes.size_28,
          fontFamily: Theme.fontFamilies.primary,
          color: Theme.colors.secondary.regular,
          fontWeight: Theme.fontWeight.bold,
        },
      },
      //about
      {
        component: "container",
        style: {
          width: "100%",
          height: 526,
          overflow: "auto",
        },
        children: [
          {
            component: "text",
            text: "A Octal é uma Software House inovadora, fundada em 2010, dedicada a transformar o desenvolvimento de sistemas por meio de soluções personalizadas e tecnológicas. Nossa missão é impulsionar a inovação, oferecendo um serviço de alta qualidade que reduz prazos e custos, enquanto nossos clientes experimentam um suporte excepcional.Contamos com uma equipe altamente qualificada e em constante atualização sobre as últimas tendências. Utilizamos uma ferramenta proprietária que garante transparência, flexibilidade e colaboração em tempo real, permitindo o gerenciamento eficiente de múltiplos projetos simultâneos. Além disso, estamos comprometidos com práticas sustentáveis e com a construção de um futuro melhor para as próximas gerações.Priorizamos a excelência em nosso trabalho, aplicando metodologias ágeis que facilitam a comunicação e o ajuste rápido às necessidades dos clientes. Nossa infraestrutura em nuvem e a integração de tecnologia de ponta asseguram desempenho superior e resultados de qualidade, com backup e suporte contínuo.Na Octal, cada projeto é único e adaptado para atender às especificidades do cliente. Junte-se a nós e faça parte dessa jornada de transformação digital e inovação.",
            style: {
              width: viewMode === "mobile" ? "98%" : "60%",
              textAlign: "justify",
              fontSize:
                viewMode === "desktop"
                  ? Theme.sizes.size_20
                  : Theme.sizes.size_18,
              // padding: 2,
              lineHeight: 1.2,
              fontFamily: Theme.fontFamilies.regular,
              color: Theme.colors.neutral.extra_ligth,
            },
          },
        ],
      },
    ],
  };
};
