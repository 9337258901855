// Default imports
import { Render } from "@8_dev/sjson-web";

import { useState, useEffect, useRef } from "react";
import { ShowPage } from "context/showPage";
import Fade from "react-reveal/Fade";
import { ShowPopup } from "context/popup";

// Theme
import Theme, { setTheme, getTheme } from "theme";

//icons
import bcgWaterTextureDark from "assets/images/bcg/bg-texture-water-dark.svg";
import bcgWaterTextureLight from "assets/images/bcg/bg-texture-water-light.svg";
import bcgFishDark from "assets/images/bcg/bg-fish-dark.svg";
import bcgFishLight from "assets/images/bcg/bg-fish-light.svg";
import bcgOctopusFooter from "assets/images/bcg/octopus.svg";
import bcgBubbles from "assets/images/bcg/bubbles.svg";
import LogoOctalFooter from "assets/images/logo/icon-octal.png";

import IconInstagram from "assets/images/logo/icon-instagram.svg";
import IconFacebook from "assets/images/logo/icon-facebook.svg";
import IconWhatsapp from "assets/images/logo/icon-whatsapp.svg";
import IconLinkedin from "assets/images/logo/icon-linkedin.svg";

import HortaIcon from "assets/images/menu/icon-horta.svg";
import PetinIcon from "assets/images/menu/icon-petin.svg";
import DreamIcon from "assets/images/menu/icon-dream.svg";

//skills
import skillDev from "assets/images/skill/devv.png";
import skillSecurity from "assets/images/skill/securityy.png";
import skillLib from "assets/images/skill/libb.png";
import skillInfra from "assets/images/skill/infraa.png";

//Como desenvolvemos
import Desenvolvimento from "assets/images/dev/desenvolvimento.svg";
import Flexibilidade from "assets/images/dev/flexibilidade.jpg";
import AmienteDev from "assets/images/dev/ambienteDev.jpg";
import Planejamento from "assets/images/dev/planejamento.svg";

//components
import AnimationText from "components/web/animationText";
import Button from "components/web/button";
import Icon from "components/web/icon";
import SkillContent from "components/web/skillContent";
import InfoContent from "components/web/infoContent";
import Input from "components/web/input";
import CardView from "components/web/cardView";
import CardInfo from "components/web/cardInfo";

//pages
import Petin from "./projects/petin/index";
import Horta from "./projects/horta/index";
import Dream from "./projects/dream/index";
import Contact from "./contact/index";
import History from "./history/index";
import Results from "./results/carrosell";

export default ({ viewMode, idxPageY, navigation, setNavigation }) => {
  const resultRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const [ContentShow, setContentShow] = useState("Petin");

  const customScrollbarStyle = () => `
    ::-webkit-scrollbar {
      width: 2px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
       background:  ${Theme.colors.bg.dark.bottom};
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `;

  useEffect(() => {
    console.log(getTheme());
    const styleTag = document.createElement("style");
    styleTag.id = "custom-scrollbar-style";
    styleTag.textContent = customScrollbarStyle();
    document.head.appendChild(styleTag);

    return () => {
      const existingStyleTag = document.getElementById(
        "custom-scrollbar-style"
      );
      if (existingStyleTag) {
        document.head.removeChild(existingStyleTag);
      }
    };
  }, []);

  const inputStyle = {
    select: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      backgroundColor: "transparent",
      borderRadius: 8,
      paddingLeft: 12,
      fontFamily: "Poppins",
      marginBottom: 4,
    },
    input: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      borderRadius: 8,
      paddingLeft: 14,
      fontFamily: "Poppins",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "1.2vh",
      marginLeft: 4,
      // color: "white",
    },
  };

  const inputName = Input({
    placeholder: "Nome completo",
    value: name,
    setValue: setName,
    style: {
      ...inputStyle,
    },
  });

  const inputEmail = Input({
    placeholder: "Email",
    value: email,
    setValue: setEmail,
    style: {
      ...inputStyle,
    },
  });

  const inputPhone = Input({
    placeholder: "Telefone",
    mask: "(99) 99999-9999",
    value: phone,
    setValue: setPhone,
    style: {
      ...inputStyle,
    },
  });

  const inputMessage = Input({
    placeholder: "Deixe-nos uma mensagem",
    value: message,
    setValue: setMessage,
    style: {
      ...inputStyle,
      input: {
        height: "12vh",
        border: "1px solid #bfbfbf",
        borderRadius: 8,
        paddingLeft: 12,
        fontFamily: "Poppins",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
  });

  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: navigation === "about" ? "100vh" : "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      transition: "background 0.5s ease",
      overflowY: "hidden",
      background:
        getTheme() === "dark"
          ? `linear-gradient(to top, ${Theme.colors.bg.dark.bottom} ${
              navigation !== "" ? "1%" : "18%"
            }, ${Theme.colors.bg.dark.top} ${
              navigation !== "" ? "26%" : "100%"
            })`
          : `linear-gradient(to top, rgba(0,0,0, 1) ${
              navigation !== "" ? "1%" : "18%"
            }, rgba(0, 126, 169, 1)  ${navigation !== "" ? "26%" : "100%"})`,
    },
    children: [
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          overflow: "hidden",
          backgroundImage:
            getTheme() === "dark"
              ? `url(${bcgWaterTextureDark})`
              : `url(${bcgWaterTextureLight})`,
          backgroundRepeat: "repeat",
          backgroundSize: "contain",
          backgroundPosition: "top",
          transition: "background 0.5s ease",
        },
        children: [
          //bcg-fish \/
          {
            component: "container",
            style: {
              display: "flex",
              maxWidth: "100%",
              height:
                viewMode === "mobile" && navigation === "result"
                  ? "100%"
                  : "100vh",
              flexDirection: "column",
              overflow: "hidden",
              backgroundImage:
                getTheme() === "dark"
                  ? `url(${bcgFishDark})`
                  : `url(${bcgFishLight})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top",
            },
            children: [
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                },
                children: [
                  //container text (Default)
                  {
                    component: "container",
                    style: {
                      display: navigation === "" ? "flex" : "none",
                      width: "100%",
                      height: "auto",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: 4,
                      gap: 32,
                    },
                    children: [
                      //textIntro
                      {
                        component: "text",
                        text: "Inovação e Excelência em Desenvolvimento de Software.",
                        style: {
                          fontFamily: Theme.fontFamilies.regular,
                          color: Theme.colors.secondary.light,
                          textAlign: "center",
                          fontSize:
                            viewMode === "desktop"
                              ? Theme.sizes.size_20
                              : Theme.sizes.size_18,
                          fontWeight: Theme.fontWeight.extraLight,
                        },
                      },
                      //slogan
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          minHeight: viewMode === "desktop" ? "6vh" : "4vh",
                          maxHeight: viewMode === "desktop" ? "6vh" : "4vh",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                        children: [
                          {
                            component: "text",
                            text: (
                              <AnimationText
                                phrases={[
                                  "</OCTAL DEV",
                                  "DESENVOLVENDO O FUTURO/>",
                                ]}
                                style={{
                                  fontSize:
                                    viewMode === "desktop"
                                      ? Theme.sizes.size_48
                                      : Theme.sizes.size_28,
                                  fontFamily: Theme.fontFamilies.primary,
                                  color: Theme.colors.secondary.regular,
                                  fontWeight: Theme.fontWeight.bold,
                                }}
                              />
                            ),
                          },
                        ],
                      },
                      //textFinish
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 4,
                        },
                        children: [
                          //objetivo
                          {
                            component: "text",
                            text:
                              viewMode === "desktop"
                                ? "A Octal Dev tem como objetivo a criação e o desenvolvimento de sistemas para atender"
                                : "A Octal Dev tem como objetivo a criação e o desenvolvimento de sistemas para atender empresas e startups",
                            style: {
                              width: "100%",
                              textAlign: "center",
                              fontSize:
                                viewMode === "desktop"
                                  ? Theme.sizes.size_20
                                  : Theme.sizes.size_18,
                              // padding: 2,
                              // lineHeight: 1.2,
                              fontFamily: Theme.fontFamilies.regular,
                              color: Theme.colors.secondary.light,
                            },
                          },
                          //ideias em realidade
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 4,
                            },
                            children: [
                              {
                                component: "text",
                                text: "empresas e startups.",
                                style: {
                                  display:
                                    viewMode === "desktop" ? "flex" : "none",
                                  textAlign: "center",
                                  fontSize:
                                    viewMode === "desktop"
                                      ? Theme.sizes.size_20
                                      : Theme.sizes.size_18,
                                  fontFamily: Theme.fontFamilies.regular,
                                  color: Theme.colors.secondary.light,
                                },
                              },
                              {
                                component: "text",
                                text: " Transformando ideias em realidade.",
                                style: {
                                  fontSize:
                                    viewMode === "desktop"
                                      ? Theme.sizes.size_20
                                      : Theme.sizes.size_18,
                                  fontFamily: Theme.fontFamilies.primary,
                                  color: Theme.colors.secondary.regular,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  //container navigation bar - Sobre
                  {
                    render: (
                      <Fade left>
                        {Render({
                          component: "container",
                          style: {
                            display: navigation === "about" ? "flex" : "none",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            paddingLeft: "6%",
                          },
                          children: [History({ viewMode })],
                        })}
                      </Fade>
                    ),
                  },
                  //container navigation bar - Resultado
                  {
                    render: (
                      <Fade left>
                        {Render({
                          component: "container",
                          style: {
                            display: navigation === "result" ? "flex" : "none",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            paddingLeft: viewMode === "desktop" ? "6%" : 0,
                          },
                          children: [Results({ viewMode, navigation })],
                        })}
                      </Fade>
                    ),
                  },
                  //container navigation bar - Contato
                  {
                    render: (
                      <Fade left>
                        {Render({
                          component: "container",
                          style: {
                            display: navigation === "contact" ? "flex" : "none",
                            width: "100%",
                            height: "100%",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            paddingLeft: "6%",
                          },
                          children: [Contact({ viewMode })],
                        })}
                      </Fade>
                    ),
                  },
                ],
              },
            ],
          },
          //conteúdo
          {
            component: "container",
            style: {
              display: navigation !== "" ? "none" : "flex",
              width: "auto",
              height: "auto",
              flexDirection: "column",
            },
            children: [
              //oque orefecemos \/
              {
                component: "container",
                style: {
                  display: "flex",
                  maxWidth: "100%",
                  height: "100vh",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                },
                children: [
                  //component box
                  SkillContent({
                    viewMode: viewMode,
                    itemsPerPage: viewMode === "mobile" ? 1 : 4,
                    item: [
                      {
                        title: "Gestão e desenvolvimento",
                        image: skillDev,
                        description:
                          "A Octal se destaca no desenvolvimento de softwares personalizados em um ambiente colaborativo e escalável em nuvem. Acompanhamos em tempo real as etapas do desenvolvimento, promovendo transparência e flexibilidade essenciais para a gestão ágil de projetos. Asseguramos uma estrutura robusta de gestão com sprints bem definidos e uma comunicação eficiente entre as equipes.",
                      },
                      {
                        title: "Biblioteca e recursos dinâmicos",
                        image: skillLib,
                        description:
                          "A biblioteca da Octal oferece uma ampla gama de funções otimizadas, que facilitam o acesso e a reutilização de código, acelerando o desenvolvimento. O consumo de API é simplificado por soluções personalizadas que se integram perfeitamente a sistemas existentes, permitindo uma comunicação eficiente e suportando um vasto conjunto de integrações para atender às necessidades específicas de cada cliente.",
                      },
                      {
                        title: "Infraestrutura Escalável",
                        image: skillInfra,
                        description:
                          "A infraestrutura da Octal é projetada para crescer conforme as demandas dos clientes, garantindo desempenho e eficiência. Isso possibilita a adaptação rápida em ambientes em nuvem, otimizando recursos e aumentando a disponibilidade dos serviços, assegurando ao mesmo tempo a estabilidade, acompanhamento e continuidade segura do negócio, investindo continuamente em tecnologias de ponta para oferecer soluções escaláveis e inovadoras.",
                      },
                      {
                        title: "Segurança do começo ao fim",
                        image: skillSecurity,
                        description:
                          "A segurança é uma prioridade na Octal, com protocolos rigorosos que incluem backups regulares, firewalls robustos e ambientes isolados para desenvolvimento e produção. Esta abordagem não só garante a integridade dos dados, mas também protege contra ameaças cibernéticas, oferecendo um controle efetivo sobre o código e os prazos, além de logs para rastreamento e monitoramento contínuo do desenvolvimento.",
                      },
                    ],
                  }),
                ],
              },
              //Comofazemos \/
              {
                component: "container",
                style: {
                  display: "flex",
                  maxWidth: "100%",
                  height: "100vh",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                },
                children: [
                  //component box
                  InfoContent({
                    viewMode: viewMode,
                    item: [
                      {
                        title: "Desenvolvimento mútuo",
                        image: Desenvolvimento,
                        style: {},
                      },
                      {
                        title: "Transparencia no planejamento",
                        image: Planejamento,
                        style: {},
                      },
                      {
                        title: "Ambientes de desenvolvimento separados.",
                        image: AmienteDev,
                        style: {},
                      },
                      {
                        title: "Flexibilidade assertiva",
                        image: Flexibilidade,
                        style: {
                          pacity: 0.6,
                        },
                      },
                    ],
                  }),
                ],
              },
              //Dev para dev \/
              {
                component: "container",
                style: {
                  display: "flex",
                  maxWidth: "100%",
                  minHeight: "100vh",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                },
                children: [
                  CardInfo({
                    viewMode: viewMode,
                    item: [
                      //cultura de trabalho
                      {
                        title: "Cultura de trabalho",
                        children: [
                          {
                            description:
                              "A Octal Dev gira em torno da flexilidade, comunicação aberta e o entendimento de seus integrantes, isso significa que adaptamos o contexto de cada membro para que ele se desenvolva dentro da octal por meio de tarefas iniciais promovendo um avançado seguro a toda equipe. Nossa cultura de trabalho é baseada na colaboração, promovendo um ambiente produtivo e inovador para todos os colaboradores.",
                          },
                        ],
                      },
                      //desenvolvimento colaborativo
                      {
                        title: "Desenvolvimento colaborativo",
                        children: [
                          {
                            description:
                              "O desenvolvimento colaborativo é incentivado por meio de metodologias ágeis e nossa ferramente de desennvolvimennto, permitindo que a equipe se adapte rapidamente às demandas dos clientes e trabalhe em harmonia e sincronizada aos prazos e qualidade que o cliente espera.",
                          },
                        ],
                      },
                      //ferramenta de desenvolvimento
                      {
                        title: "Ferramenta de desenvolvimento",
                        children: [
                          {
                            description:
                              "Nossa ferramenta de desenvolvimento (code) proporciona flexibilidade e eficiência, reduzindo prazos e custos para todos, além de oferecer funcionalidades que otimizam a qualidade do software e ajudam no desenvolvimento profissinal da nossa equipe. Exemplificando: Acessibilidade, é o prinncipal foco da nossa ferramenta, basta acessa-lá através de qualquer nnavegador web e dispositivo e desenvolver seu software.",
                          },
                        ],
                      },
                      //Acompanhamento em tempo real
                      {
                        title: "Acompanhamento em tempo real",
                        children: [
                          {
                            description:
                              "Utilizamos de todos recursos para acompanhar em tempo real o progresso da equipe utilizando softwares para gerênciar as demandas definidas em nosso modelo e reuniões diariamente para executar e se adaptar as necessidades da equipe e do. cliente.",
                          },
                        ],
                      },
                    ],
                  }),
                ],
              },
              //resultado
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  minHeight: "100vh",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 42,
                },
                children: [
                  // Text info
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: Theme.sizes.size_12,
                    },
                    children: [
                      //title
                      {
                        component: "text",
                        text: "NOSSAS CONQUISTAS",
                        style: {
                          color: Theme.colors.primary.extra_ligth,
                          fontSize:
                            viewMode === "desktop"
                              ? Theme.sizes.size_48
                              : Theme.sizes.size_28,
                          fontFamily: Theme.fontFamilies.primary,
                          transition: "color 0.3s ease, transform 0.3s ease",
                        },
                      },
                      //descrição
                      {
                        component: "text",
                        text: "Projetos que fazem parte da nossa história",
                        style: {
                          color: Theme.colors.primary.extra_ligth,
                          fontSize:
                            viewMode === "desktop"
                              ? Theme.sizes.size_18
                              : Theme.sizes.size_16,
                          fontFamily: Theme.fontFamilies.regular,
                          textAlign: "center",
                          transition: "color 0.3s ease, transform 0.3s ease",
                          color: Theme.colors.secondary.medium,
                        },
                      },
                    ],
                  },
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "auto",
                      flexDirection: viewMode === "desktop" ? "row" : "column",
                      paddingLeft: viewMode === "desktop" ? "8%" : 0,
                      gap: 12,
                    },
                    children: [
                      //navBar
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          height: viewMode === "desktop" ? "100%" : "20%",
                          width: viewMode === "desktop" ? "30%" : "100%",
                        },
                        children: [
                          CardView({
                            infoIntro: "Petin",
                            style: {
                              boxMain: {
                                flexDirection:
                                  viewMode === "desktop" ? "column" : "row",
                                width: "auto",
                              },
                            },
                            item: [
                              //PETIN
                              {
                                page: "Petin",
                                title: "Petin digital",
                                image: {
                                  src: PetinIcon,
                                },
                                style: {
                                  iconBox: {
                                    display:
                                      viewMode === "desktop" ? "none" : "flex",
                                  },
                                  title: {
                                    display:
                                      viewMode === "desktop" ? "flex" : "none",
                                  },
                                },
                                onClick: (page) => {
                                  setContentShow(page);
                                },
                              },
                              //HORTA
                              {
                                page: "Horta",
                                title: "Da hora pra horta",
                                image: {
                                  src: HortaIcon,
                                },
                                style: {
                                  iconBox: {
                                    display:
                                      viewMode === "desktop" ? "none" : "flex",
                                  },
                                  title: {
                                    display:
                                      viewMode === "desktop" ? "flex" : "none",
                                  },
                                },
                                onClick: (page) => {
                                  setContentShow(page);
                                },
                              },
                              //DREAM
                              {
                                page: "Dream",
                                title: "Dream Buffets",
                                image: {
                                  src: DreamIcon,
                                },
                                style: {
                                  iconBox: {
                                    display:
                                      viewMode === "desktop" ? "none" : "flex",
                                  },
                                  title: {
                                    display:
                                      viewMode === "desktop" ? "flex" : "none",
                                  },
                                },
                                onClick: (page) => {
                                  setContentShow(page);
                                },
                              },
                            ],
                          }),
                        ],
                      },
                      //content
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          height: "100%",
                          maxWidth: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: 2,
                        },
                        children: [
                          Petin({ viewMode, ContentShow }),
                          Horta({ viewMode, ContentShow }),
                          Dream({ viewMode, ContentShow }),
                        ],
                      },
                    ],
                  },
                ],
              },
              //fale conosco
              {
                component: "container",
                style: {
                  display: "flex",
                  width: "100%",
                  minHeight: "auto",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 32,
                },
                children: [
                  // Text info
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: Theme.sizes.size_12,
                    },
                    children: [
                      //title
                      {
                        component: "text",
                        text: "FALE CONOSCO",
                        style: {
                          color: Theme.colors.primary.extra_ligth,
                          fontSize:
                            viewMode === "desktop"
                              ? Theme.sizes.size_48
                              : Theme.sizes.size_28,
                          fontFamily: Theme.fontFamilies.primary,
                          transition: "color 0.3s ease, transform 0.3s ease",
                        },
                      },
                      //descrição
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          flexDirection:
                            viewMode === "desktop" ? "row" : "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 4,
                        },
                        children: [
                          //titulo do assunto
                          {
                            component: "text",
                            text: "Se identificou com seus objetivos?",
                            style: {
                              color: Theme.colors.primary.extra_ligth,
                              fontSize: Theme.sizes.size_18,
                              fontFamily: Theme.fontFamilies.regular,
                              textAlign: "center",
                              padding:
                                viewMode === "desktop" ? 0 : Theme.sizes.size_4,
                              transition:
                                "color 0.3s ease, transform 0.3s ease",
                            },
                          },
                          //titulo do assunto
                          {
                            component: "text",
                            text: "a gente te ajuda!",
                            style: {
                              color: Theme.colors.secondary.medium,
                              fontSize: Theme.sizes.size_18,
                              fontFamily: Theme.fontFamilies.regular,
                            },
                          },
                        ],
                      },
                    ],
                  },
                  //form
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: viewMode === "desktop" ? "62vh" : "92%",
                      height: "auto",
                      padding: 12,
                      gap: 12,
                    },
                    children: [
                      inputName,
                      inputEmail,
                      inputPhone,
                      inputMessage,
                      //btnSend
                      {
                        component: "container",
                        style: {
                          marginTop: 12,
                          width: 256,
                          height: 42,
                        },
                        children: [
                          Button({
                            style: {
                              button: {
                                width: "100%",
                                height: "100%",
                                backgroundColor: Theme.colors.secondary.regular,
                                border: "none",
                                borderRadius: 8,
                              },
                              label: {
                                fontSize: 24,
                                fontFamily: "Poppins",
                                color: Theme.colors.primary.regular,
                              },
                            },
                            label: "Enviar",
                            onPress: () => {
                              console.log(name, email, phone, message);
                            },
                          }),
                        ],
                      },
                    ],
                  },
                ],
              },
              //bcg-octopus + footer
              {
                component: "container",
                style: {
                  display: "flex",
                  maxWidth: "100%",
                  height: "54vh",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  padding: 12,
                  backgroundImage: `url(${bcgOctopusFooter})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition:
                    viewMode === "desktop" ? "center 10px" : "bottom",
                },
                children: [
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      flexDirection: "column",
                    },
                    children: [
                      //footer
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          padding: 12,
                          gap: 12,
                        },
                        children: [
                          //image octal
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "auto",
                              height: "auto",
                              gap: 8,
                            },
                            children: [
                              //image
                              {
                                component: "image",
                                src: LogoOctalFooter,
                                style: {
                                  width: 32,
                                  height: 32,
                                },
                              },
                              //text
                              {
                                component: "container",
                                style: {
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "auto",
                                  width: "auto",
                                  alignItems: "flex-end",
                                },
                                children: [
                                  {
                                    component: "text",
                                    text: "<octal>",
                                    style: {
                                      fontFamily: Theme.fontFamilies.primary,
                                      fontWeight: "bold",
                                      fontSize: 18,
                                      color: "white",
                                    },
                                  },
                                  {
                                    component: "text",
                                    text: ".dev",
                                    style: {
                                      fontFamily: Theme.fontFamilies.primary,
                                      fontWeight: "bold",
                                      fontSize: 12,
                                      color: "white",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          //links
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "auto",
                              height: "auto",
                              gap: 8,
                            },
                            children: [
                              Icon({
                                src: IconInstagram,
                                srcHover: "",
                                size: Theme.sizes.size_24,
                                style: {
                                  iconBox: {
                                    width: "auto",
                                  },
                                },
                                onPress: () => {
                                  console.log("oi");
                                },
                              }),
                              Icon({
                                src: IconFacebook,
                                srcHover: "",
                                size: Theme.sizes.size_24,
                                style: {
                                  iconBox: {
                                    width: "auto",
                                  },
                                },
                                onPress: () => {},
                              }),
                              Icon({
                                src: IconWhatsapp,
                                srcHover: "",
                                size: Theme.sizes.size_24,
                                style: {
                                  iconBox: {
                                    width: "auto",
                                  },
                                },
                                onPress: () => {},
                              }),
                              Icon({
                                src: IconLinkedin,
                                srcHover: "",
                                size: Theme.sizes.size_24,
                                style: {
                                  iconBox: {
                                    width: "auto",
                                  },
                                },
                                onPress: () => {},
                              }),
                            ],
                          },
                        ],
                      },
                      //direitos
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "auto",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          flexDirection: "column",
                        },
                        children: [
                          //text
                          {
                            component: "text",
                            text: "© Todos os direitos reservados a OctalDev.",
                            style: {
                              fontStyle: "italic",
                              fontSize:
                                viewMode === "desktop" ? "0.66vw" : "1vh",
                              fontFamily: "Poppins",
                              color: "#6A6A6A",
                            },
                          },
                          //text
                          {
                            component: "text",
                            text: "OCTOPUS TECNOLOGIA LTDA  - 45.644.187/0001-02",
                            style: {
                              fontStyle: "italic",
                              fontSize:
                                viewMode === "desktop" ? "0.66vw" : "1vh",
                              fontFamily: "Poppins",
                              color: "#6A6A6A",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
};
