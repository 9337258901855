//default imports
import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

//Context
import { ShowPage } from "context/showPage";

// Theme
import Theme, { setTheme, getTheme } from "theme";

//components
import PopUp from "components/web/popup";
import TopBAR from "components/web/topBar";

//pages
import Intro from "../intro";

let Page = () => {
  // const { Data, Set } = Context();
  const [navigation, setNavigation] = useState("");
  const [viewMode, setViewMode] = useState("desktop");
  const [idxPageY, setIdxPageY] = useState();

  //manipula o viewMode
  useEffect(async () => {
    const { innerWidth: window_width, innerHeight: window_height } = window;
    const proportion = window_width / window_height;
    if (proportion < 1) setViewMode("mobile");
    else setViewMode("desktop");
    function handleResize() {
      const { innerWidth: window_width, innerHeight: window_height } = window;
      const proportion = window_width / window_height;
      if (proportion < 1) setViewMode("mobile");
      else setViewMode("desktop");
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //monitora a rolagem do usúario
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const maxScroll = document.body.scrollHeight - window.innerHeight;
      const scrollFraction = Math.min(scrollY / maxScroll, 1);
      setIdxPageY(scrollY);

      const startColor = getTheme() === "dark" ? [2, 41, 74] : [0, 126, 169];
      const endColor = [0, 0, 0];
      const currentColor = startColor.map((start, i) =>
        Math.round(start + (endColor[i] - start) * scrollFraction)
      );

      const colorHex = `#${currentColor
        .map((c) => c.toString(16).padStart(2, "0"))
        .join("")}`;

      document.body.style.backgroundColor = colorHex;
      const metaThemeColor = document.querySelector("meta[name='theme-color']");
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", colorHex);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const contentParam = {
    viewMode,
    idxPageY,
    navigation,
    setNavigation,
  };

  return Render({
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
      overflowY: "hidden",
    },
    children: [
      PopUp(),
      TopBAR({
        view: viewMode,
        indexY: idxPageY,
        item: [
          {
            option: "Início",
            onClick: () => {
              setNavigation("");
            },
          },
          {
            option: "Sobre",
            onClick: () => {
              setNavigation("about");
            },
          },
          {
            option: "Resultados",
            onClick: () => {
              setNavigation("result");
              // document.getElementById("result").scrollIntoView({
              //   behavior: "smooth",
              //   block: "start",
              // });
            },
          },
          {
            option: "Contato",
            onClick: () => {
              setNavigation("contact");
            },
          },
        ],
        itemMobile: [
          {
            option: "Início",
            onClick: () => {
              setNavigation("");
            },
          },
          {
            option: "Sobre",
            onClick: () => {
              setNavigation("about");
            },
          },
          {
            option: "Resultados",
            onClick: () => {
              setNavigation("result");
            },
          },
          {
            option: "Contato",
            onClick: () => {
              setNavigation("contact");
            },
          },
        ],
      }),
      //Page
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
              },
              children: [Intro(contentParam)],
            })}
          </Fade>
        ),
      },
    ],
  });
};

export default Page;
