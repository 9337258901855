// Default imports
import { Render } from "@8_dev/sjson-web";

import { useState, useEffect, useRef } from "react";
import { ShowPage } from "context/showPage";
import Fade from "react-reveal/Fade";
import { ShowPopup } from "context/popup";

// Theme
import Theme, { setTheme, getTheme } from "theme";
import AnimationText from "components/web/animationText";
import SkillContent from "components/web/skillContent";

//skills
import skillDev from "assets/images/skill/devv.png";
import skillSecurity from "assets/images/skill/securityy.png";
import skillLib from "assets/images/skill/libb.png";
import skillInfra from "assets/images/skill/infraa.png";

//components
import Icon from "components/web/icon";

export default ({ viewMode, idxPageY, navigation, setNavigation }) => {
  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: viewMode === "desktop" ? 8 : 24,
      padding: 12,
    },
    children: [
      //textIntro
      {
        component: "container",
        style: {
          width: "100%",
          display: "flex",
          flexDirection: viewMode === "desktop" ? "row" : "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 12,
          marginTop: viewMode === "desktop" ? 96 : 126,
        },
        children: [
          //textIntro
          {
            component: "text",
            text: "Por que nossos resultados",
            style: {
              width: "auto",
              textAlign: "flex-start",
              fontSize:
                viewMode === "desktop"
                  ? Theme.sizes.size_32
                  : Theme.sizes.size_26,
              fontFamily: Theme.fontFamilies.primary,
              color: Theme.colors.primary.extra_ligth,
              fontWeight: Theme.fontWeight.bold,
            },
          },
          //titleX
          {
            component: "text",
            text: "são 100% garantidos ?",
            style: {
              width: "auto",
              textAlign: "flex-start",
              fontSize:
                viewMode === "desktop"
                  ? Theme.sizes.size_32
                  : Theme.sizes.size_28,
              fontFamily: Theme.fontFamilies.primary,
              color: Theme.colors.secondary.regular,
              fontWeight: Theme.fontWeight.bold,
            },
          },
        ],
      },
      //textAnimation
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          minHeight: viewMode === "desktop" ? "6vh" : "4vh",
          maxHeight: viewMode === "desktop" ? "6vh" : "4vh",
          justifyContent: viewMode === "desktop" ? "flex-start" : "center",
          alignItems: "center",
        },
        children: [
          navigation === "result" && {
            component: "text",
            text: (
              <AnimationText
                phrases={[
                  "Simples!",
                  "Pensamos em cada passo e te contamos a seguir.",
                ]}
                delay={1000}
                style={{
                  fontSize: 22,
                  fontFamily: Theme.fontFamilies.regular,
                  color: Theme.colors.secondary.extra_light,
                  fontWeight: "200",
                  marginLeft: 0,
                }}
              />
            ),
          },
        ],
      },
      //carrosell
      SkillContent({
        style: {
          textIntro: {
            display: "none",
          },
          boxMain: {
            alignItems: "flex-start",
          },
          boxText: {
            alignItems: viewMode === "desktop" ? "flex-start" : "center",
          },
          cardItem: {
            border: 0,
          },
          cardImage: {
            opacity: 1,
            transform: "",
          },
        },
        itemsPerPage: 1,
        viewMode: viewMode,
        item: [
          {
            title: "Planejamento",
            image: skillDev,
            description:
              "A Octal se destaca no desenvolvimento de softwares personalizados em um ambiente colaborativo e escalável em nuvem. Acompanhamos em tempo real as etapas do desenvolvimento, promovendo transparência e flexibilidade essenciais para a gestão ágil de projetos. Asseguramos uma estrutura robusta de gestão com sprints bem definidos e uma comunicação eficiente entre as equipes.",
          },
          {
            title: "Biblioteca e recursos dinâmicos",
            image: skillLib,
            description:
              "A biblioteca da Octal oferece uma ampla gama de funções otimizadas, que facilitam o acesso e a reutilização de código, acelerando o desenvolvimento. O consumo de API é simplificado por soluções personalizadas que se integram perfeitamente a sistemas existentes, permitindo uma comunicação eficiente e suportando um vasto conjunto de integrações para atender às necessidades específicas de cada cliente.",
          },
          {
            title: "Infraestrutura Escalável",
            image: skillInfra,
            description:
              "A infraestrutura da Octal é projetada para crescer conforme as demandas dos clientes, garantindo desempenho e eficiência. Isso possibilita a adaptação rápida em ambientes em nuvem, otimizando recursos e aumentando a disponibilidade dos serviços, assegurando ao mesmo tempo a estabilidade, acompanhamento e continuidade segura do negócio, investindo continuamente em tecnologias de ponta para oferecer soluções escaláveis e inovadoras.",
          },
          {
            title: "Segurança do começo ao fim",
            image: skillSecurity,
            description:
              "A segurança é uma prioridade na Octal, com protocolos rigorosos que incluem backups regulares, firewalls robustos e ambientes isolados para desenvolvimento e produção. Esta abordagem não só garante a integridade dos dados, mas também protege contra ameaças cibernéticas, oferecendo um controle efetivo sobre o código e os prazos, além de logs para rastreamento e monitoramento contínuo do desenvolvimento.",
          },
        ],
      }),
    ],
  };
};
