// Default imports
import { Render } from "@8_dev/sjson-web";

import { useState, useEffect, useRef } from "react";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

// Theme
import Theme, { setTheme, getTheme } from "theme";

//images
import IconInstagram from "assets/images/logo/icon-instagram.svg";
import IconFacebook from "assets/images/logo/icon-facebook.svg";
import IconWhatsapp from "assets/images/logo/icon-whatsapp.svg";
import IconLinkedin from "assets/images/logo/icon-linkedin.svg";

//components
import Button from "components/web/button";
import Icon from "components/web/icon";
import Input from "components/web/input";

export default ({ viewMode, idxPageY, navigation, setNavigation }) => {
  const resultRef = useRef(null);
  const { PageInfo } = ShowPage();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const { PopupShow, PopupClose } = ShowPopup();

  const inputStyle = {
    select: {
      width: "100%",
      height: 48,
      border: "1px solid #bfbfbf",
      backgroundColor: "transparent",
      borderRadius: 8,
      paddingLeft: 12,
      fontFamily: "Poppins",
      marginBottom: 4,
    },
    input: {
      width: "100%",
      height: 48,
      color: "white",
      border: "1px solid #bfbfbf",
      borderRadius: 8,
      fontFamily: "Poppins",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "1.2vh",
      marginLeft: 4,
      // color: "white",
    },
  };

  const inputName = Input({
    placeholder: "Nome completo",
    value: name,
    setValue: setName,
    style: {
      ...inputStyle,
    },
  });

  const inputEmail = Input({
    placeholder: "Email",
    value: email,
    setValue: setEmail,
    style: {
      ...inputStyle,
    },
  });

  const inputPhone = Input({
    placeholder: "Telefone",
    mask: "(99) 99999-9999",
    value: phone,
    setValue: setPhone,
    style: {
      ...inputStyle,
    },
  });

  const inputMessage = Input({
    placeholder: "Deixe-nos uma mensagem",
    value: message,
    setValue: setMessage,
    style: {
      ...inputStyle,
      input: {
        height: "12vh",
        border: "1px solid #bfbfbf",
        borderRadius: 8,
        paddingLeft: 12,
        fontFamily: "Poppins",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
  });

  return {
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: 32,
      paddingTop: 92,
    },
    children: [
      //textIntro
      {
        component: "text",
        text: "Entre em contato",
        style: {
          width: "100%",
          textAlign: "flex-start",
          fontSize:
            viewMode === "desktop" ? Theme.sizes.size_32 : Theme.sizes.size_28,
          fontFamily: Theme.fontFamilies.primary,
          color: Theme.colors.secondary.regular,
          fontWeight: Theme.fontWeight.bold,
        },
      },
      //textSendMessenge
      {
        component: "container",
        style: {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 8,
        },
        children: [
          //text
          {
            component: "text",
            text: "Fique tranquilo!",
            style: {
              fontFamily: Theme.fontFamilies.regular,
              color: Theme.colors.secondary.light,
              textAlign: "flex-start",
              fontSize:
                viewMode === "desktop"
                  ? Theme.sizes.size_20
                  : Theme.sizes.size_18,
              fontWeight: Theme.fontWeight.extraLight,
            },
          },
          //text
          {
            component: "text",
            text: "Sua mensagem chegará em todos os contatos OctalDev.",
            style: {
              fontFamily: Theme.fontFamilies.regular,
              color: Theme.colors.secondary.light,
              textAlign: "flex-start",
              fontSize:
                viewMode === "desktop"
                  ? Theme.sizes.size_20
                  : Theme.sizes.size_18,
              fontWeight: Theme.fontWeight.extraLight,
            },
          },
          //links
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "auto",
              height: "auto",
              gap: 8,
            },
            children: [
              Icon({
                src: IconInstagram,
                srcHover: "",
                size: Theme.sizes.size_24,
                style: {
                  iconBox: {
                    width: "auto",
                  },
                },
                onPress: () => {
                  console.log("oi");
                },
              }),
              Icon({
                src: IconFacebook,
                srcHover: "",
                size: Theme.sizes.size_24,
                style: {
                  iconBox: {
                    width: "auto",
                  },
                },
                onPress: () => {},
              }),
              Icon({
                src: IconWhatsapp,
                srcHover: "",
                size: Theme.sizes.size_24,
                style: {
                  iconBox: {
                    width: "auto",
                  },
                },
                onPress: () => {},
              }),
              Icon({
                src: IconLinkedin,
                srcHover: "",
                size: Theme.sizes.size_24,
                style: {
                  iconBox: {
                    width: "auto",
                  },
                },
                onPress: () => {},
              }),
            ],
          },
        ],
      },
      //form
      {
        component: "container",
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: viewMode === "desktop" ? 526 : "96%",
          height: "auto",
          gap: 12,
        },
        children: [
          inputName,
          inputEmail,
          inputPhone,
          inputMessage,
          //btnSend
          {
            component: "container",
            style: {
              marginTop: 12,
              width: 256,
              height: 42,
            },
            children: [
              Button({
                style: {
                  button: {
                    width: "100%",
                    height: "100%",
                    backgroundColor: Theme.colors.secondary.regular,
                    border: "none",
                    borderRadius: 8,
                  },
                  label: {
                    fontSize: 24,
                    fontFamily: "Poppins",
                    color: Theme.colors.primary.regular,
                  },
                },
                label: "Enviar",
                onPress: () => {
                  console.log(name, email, phone, message);
                },
              }),
            ],
          },
        ],
      },
    ],
  };
};
